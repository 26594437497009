import React from "react"
import styled from "styled-components"
import "normalize.css"

const Ipad = ({ data, children }) => {
  return (
    <Container style={data.bgColor && { backgroundColor: data.bgColor }}>
      {children}
    </Container>
  )
}

const Container = styled.main`
  width: 1180px;
  height: 820px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default Ipad
