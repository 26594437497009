import React from "react"
import Ipad from "../components/Ipad.js"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const thanks = () => {
  return (
    <Ipad data={{ bgColor: "var(--blu)" }}>
      <Container>
        <div className="topbar">
          <StaticImage
            className="logo"
            src="../images/logo-az.png"
            alt="logo"
          />
          <p>oio.</p>
        </div>
        <h1>¡Gracias!</h1>
        <Link to="/">
          <button>¿de nuevo?</button>
        </Link>
      </Container>
    </Ipad>
  )
}

const Container = styled.div`
  position: fixed;
  width: 98%;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: GEB;
  color: white;
  .topbar {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 2.5rem;
      margin: 0;
    }
    .logo {
      width: 160px;
      img {
        object-fit: contain !important;
        width: 100%;
      }
    }
  }
  h1 {
    margin: 0;
    margin-top: 200px;
    font-size: 7rem;
  }
  button {
    border: 2px solid var(--azul);
    background: transparent;
    color: white;
    font-family: GEB;
    font-size: 1.8rem;
    border-radius: 4rem;
    margin-top: 30px;
    padding: 1rem 1.8rem;
  }
`

export default thanks
